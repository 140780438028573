<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <div class="tags">
              <div
                class="essilor-tag"
                v-for="(opt, index) of form.value"
                :key="index"
                v-b-tooltip.hover :title="opt?.length >= 120 ? opt : ''"
                @click="openFile(opt)"
              >
                <PdfIcon />
                {{ opt?.name?.length >= 120 ? opt.name.slice(0, 120) + '...' : opt.name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    PdfIcon: () => import('@/assets/icons/pdf-icon.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.imagensExamesEssilor,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['destroyMedicalRecord']),
    ...mapActions('attendance/form/imagensExamesEssilor', ['handleProps']),
    async openFile(opt) {
      if (opt?.path) {
        const loading = this.$loading.show()
        try {
          const { data } = await this.api.getDocTemporaryUrl(opt.path)
          window.open(data, '_blank')
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          loading.hide()
        }
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value = []
    }
  }
}
</script>
<style lang="scss" scoped>
.essilor-tag {
  display: inline-flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--type-active);
  padding: 4px 16px;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--neutral-300);
  border-radius: 100px;
  margin: 0 8px 8px 0;

  &:not(.selected) {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--light-orange-200);
    svg{
      margin-left: 10px;
      width: 20px;
      fill: var(--type-active);
      cursor: pointer;
    }
  }
}

</style>